import React from "react";
import Image from "next/image";
import roposoLogo from "./roposo-logo.svg";
import lineStreak from "./line-streak.svg";
import useDomainInfo from "hooks/determine-client-domain";

interface HeaderProps {
  pageTitle: string;
}

const Header = ({pageTitle}: HeaderProps) => {
  const {isShopTab, hasWindow} = useDomainInfo();
  if (hasWindow && isShopTab) {
    return <div className="w-full mt-16">
      <div className="text-center">
        <Image src={roposoLogo}/>
      </div>
      <div className="mb-2 py-1 text-center">
        <Image src={lineStreak}/>
      </div>
      <div className="w-full text-left text-neutral-950 text-2xl font-bold leading-none mt-2 mb-4">{pageTitle}</div>
    </div>;
  }
  return <div className="w-full text-left text-neutral-950 text-2xl font-bold leading-none mt-32 mb-4">{pageTitle}</div>;
};

export default Header;