import { useEffect, useState } from 'react';
import {isShipstreakDomain, isShopTabDomain} from "utils/misc";

export default function useDomainInfo() {

  const hasWindow = typeof window !== 'undefined';

  function getDomainInfo() {
    const isShopTab = isShopTabDomain();
    const isShipstreak = isShipstreakDomain();
    return {
      isShopTab,
      isShipstreak,
      hasWindow,
    };
  }

  const [domainInfo, setDomainInfo] = useState(getDomainInfo());

  useEffect(() => {
    if (hasWindow) {
      setDomainInfo(getDomainInfo());
    }
  }, [hasWindow]);

  return domainInfo;
}