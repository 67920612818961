import React from 'react';
import Footer from '../_default/Footer';
import {useRouter} from "next/router";
import {isMobileNumber} from "utils/validation";
import {handleSendOtp, handleVerifyOtp, handleResendOtp} from "handlers/returns-page";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {COOKIES, getCookieDomain, setCookie} from "utils/cookies";
import {NextPageContext} from "next";
import {isUserLoggedIn} from "utils/auth";
import Image from "next/image";
import Head from "next/head";
import Header from "../_default/Header";

interface FormErrors {
  mobileNumber?: string
}

interface CustomCredentialRequestOptions {
  otp?: {
    transport: string[];
  };
  signal?: AbortSignal;
}

function ReturnsLoginPage() {
  const [pageTitle, setPageTitle] = React.useState('Raise a Return Request');
  const [page, setPage] = React.useState('login');
  const [authDetails, setAuthDetails] = React.useState({authToken: ""});
  const [mobileNumber, setMobileNumnber] = React.useState('');
  const [otp, setOtp] = React.useState<string[]>(["", "", "", "", "", ""]);
  const [resendOtpTimer, setResendOtpTimer] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [timeoutId, setTimeoutId] = React.useState<any>(null);

  const router = useRouter();
  //web otp api credential function 
  const requestWebOTPPermission = React.useCallback(async() => {
      if("OTPCredential" in window) {
      const ac = new AbortController();

      const options: CustomCredentialRequestOptions = {
        otp: { transport: ["sms"] },
        signal: ac.signal,
      };
      navigator.credentials.get(options).then((otp: any) => {
          const getOtp = otp.code.split("");
          setOtp(getOtp);
          ac.abort();
      }).catch((err: any) => {
        ac.abort();
        console.log(err);
      });
    }
  }, []);
  const requestOtpHandler = React.useCallback(() => {
    setPageTitle('Verify OTP');
    setPage('verify');
    setOtp(["", "", "", "", "", ""]);
  }, []);

  const changeMobileNumberHandler = React.useCallback(() => {
    setPageTitle('Raise a Return Request');
    setPage('login');
    setMobileNumnber("");
  }, []);

  React.useEffect(() => {
    if (resendOtpTimer > 0) {
      setTimeoutId(setTimeout(() => {
        setResendOtpTimer((prevState) => prevState - 1);
      }, 1000));
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [resendOtpTimer]);

  const resendOtpHandler = React.useCallback(async () => {
    await handleResendOtp(authDetails.authToken);
    setResendOtpTimer(5);

  }, [authDetails]);

  const verifyOtpHandler = React.useCallback(async () => {
    setIsLoading(true);
    const userDetails = await handleVerifyOtp(mobileNumber, otp.join(''), authDetails.authToken);
    setIsLoading(false);
    requestWebOTPPermission();
    if (userDetails) {
      setCookie(null, COOKIES.shop101Session, JSON.stringify(userDetails), getCookieDomain(window.location.hostname));
      router.replace('/returns/orders');
    }
  }, [mobileNumber, otp, authDetails]);

  const otpChangeHandler = React.useCallback((e) => {
    const newOtp = [...otp];
    const value = e.target.value;
    const name = e.target.name;
    if (value && !/\d+/.test(value)) {
      return;
    }
    const nextSibling = e.target.parentElement.nextSibling;
    if (value.length > 1) {
      e.target.value = value.slice(0, 1);
    } else {
      if (value && nextSibling) {
        nextSibling.firstChild.focus();
      }
      newOtp[parseInt(name.slice(-1))] = value;
      setOtp(newOtp);
    }
  }, [otp]);

  const otpKeyDownHandler = React.useCallback((e) => {
    setTimeout(() => {
      const value = e.target.value;
      const name = e.target.name;
      const prevSibling = e.target.parentElement.previousSibling;
      if (prevSibling && !(value || otp[parseInt(name.slice(-1))])) {
        prevSibling.firstChild.focus();
      }
    }, 100)
  }, [otp]);

  const loginComponent = <>
    <div className={'flex-1 text-center w-full mb-16'}>
      <Formik
        initialValues={{
          mobileNumber: ""
        }}
        validate={values => {
          const errors: FormErrors = {};
          if (!values.mobileNumber) {
            errors.mobileNumber = 'Please enter mobile number'
          } else if (!isMobileNumber(values.mobileNumber)) {
            errors.mobileNumber = 'Invalid mobile number'
          }
          return errors;
        }}
        onSubmit={async (values, {setSubmitting}) => {
          const userDetails = await handleSendOtp(values.mobileNumber, setSubmitting);
          //reqeusting for the web otp
          requestWebOTPPermission();
          // @ts-ignore
          setAuthDetails(userDetails);
          setMobileNumnber(values.mobileNumber);
          requestOtpHandler();
        }}>
        {({
            isSubmitting,
          }) => {
          return (
            <Form>
              <div className={'text-left mb-16'}>
                <div className="text-neutral-400 text-sm font-normal leading-[16.80px] tracking-tight">
                  Enter your mobile number to get started
                </div>
              </div>
              <div className={'text-left'}>
                <div className="text-black text-sm font-medium leading-[16.80px] tracking-tight mb-3">Enter Mobile
                  No.:
                </div>
                <div
                  className="relative w-full h-8 px-3 py-1.5 bg-white rounded border border-stone-300 justify-start items-center gap-1 inline-flex mb-8">
                  <Field type="text"
                         name={'mobileNumber'}
                         className="w-full text-black text-sm font-medium leading-[16.80px] tracking-tight"/>
                  <div
                    className={'absolute -bottom-1 left-0 transform translate-y-full text-red-600 text-xs font-normal tracking-tight'}>
                    <ErrorMessage name={'mobileNumber'}/>
                  </div>
                </div>
              </div>
              <button
                className="w-full h-10 px-4 py-2 bg-black rounded justify-center items-center gap-1 inline-flex text-center text-white text-base font-medium leading-normal disabled:opacity-20"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting && <Image className="animate-spin"
                                        width="24px"
                                        height="24px"
                                        src="/Spinner.svg"/>}
                Request OTP
              </button>
            </Form>
          )
        }}
      </Formik>
    </div>
  </>;

  const verifyOtpComponent = <>
    <div className={'flex-1 text-center w-full mb-12'}>
      <div className={'text-left mb-8'}>
        <div className="text-neutral-400 text-sm font-normal leading-[16.80px] tracking-tight mb-3">
          We’ve sent a 6 digit OTP to your mobile number
        </div>
        <div className={'flex items-center gap-2'}>
          <span className="text-black text-base font-medium leading-tight tracking-tight">{mobileNumber}</span>
          <button onClick={changeMobileNumberHandler}>
            <a className="text-blue-600 text-sm font-normal underline leading-[16.80px] tracking-tight">Change</a>
          </button>
        </div>
      </div>
      <div className={'text-left'}>
        <div className="text-black text-sm font-medium leading-[16.80px] tracking-tight mb-3">Enter OTP:</div>
        <div
          className="w-full flex justify-between items-center mb-8">
            {otp.map((digit, index) => (
              <div
              key={`otp${index}`}
              className="w-1/6 max-w-[40px] h-8 px-3 py-1.5 bg-white rounded border border-stone-300 justify-center items-center gap-1 inline-flex">
              <input className="w-full text-center text-black text-base font-medium leading-tight tracking-tight"
                     inputMode="numeric"
                     name={`otp${index}`}
                     onChange={otpChangeHandler}
                     onKeyDown={otpKeyDownHandler}
                     value={otp[index]}/>
            </div>
            ))}
        </div>
      </div>
      <div>
        <button
          className="w-full h-10 px-4 py-2 bg-black rounded justify-center items-center gap-1 inline-flex text-center text-white text-base font-medium leading-normal mb-8 disabled:opacity-20"
          onClick={verifyOtpHandler}
          disabled={isLoading || otp.filter(val => !!val).length !== 6}>
          {isLoading && <Image className="animate-spin"
                               width="24px"
                               height="24px"
                               src="/Spinner.svg"/>}
          Verify OTP
        </button>
        <div>
          <span
            className="text-neutral-400 text-sm font-normal leading-[16.80px] tracking-tight">Didn’t receive OTP? </span>
          <button className="disabled:opacity-20"
                  onClick={resendOtpHandler}
                  disabled={resendOtpTimer !== 0}>
            <a className="text-blue-600 text-sm font-normal underline leading-[16.80px] tracking-tight">Resend Code</a>
          </button>
          {resendOtpTimer !== 0 && <span
            className="text-neutral-400 text-sm font-normal leading-[16.80px] tracking-tight"> in {resendOtpTimer}s</span>}
        </div>
      </div>
    </div>
  </>;

  return (
    <div className={'relative flex flex-col items-center min-h-screen p-8'}>
      <Head>
        <link
          rel="preload"
          href="/Spinner.svg"
          as="image"
        />
        <title>Login</title>
      </Head>
      <Header pageTitle={pageTitle} />
      {page === 'login' && loginComponent}
      {page === 'verify' && verifyOtpComponent}
      <Footer/>
    </div>
  );
}

export const getServerSideProps = async (ctx: NextPageContext) => {
  if (isUserLoggedIn(ctx)) {
    return {
      redirect: {
        destination: `/returns/orders`,
        permanent: false,
      },
    };
  }
  return {
    props: {},
  };
};

export default ReturnsLoginPage;
